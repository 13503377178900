import React, { Component } from 'react';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/styles';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import './css/newsletter-signup.css';

const CssTextField = withStyles({
  root: {
    '& input': {
      fontFamily: 'Raleway',
      color: 'white',
    },
    '& label': {
      fontFamily: 'Raleway',
      color: 'white',
    },
    '& label.Mui-focused': {
      color: '#CD9953',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#CD9953',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'white',
        color: 'white',
      },
      '&:hover fieldset': {
        borderColor: 'white',
        opacity: 0.7,
      },
      '&.Mui-focused fieldset': {
        borderColor: 'white',
        color: 'white',
      },
    },
  },
})(TextValidator);


class NewsletterSignup extends Component {
  constructor(props) {
    super(props);

    this._submitNewsletterSignup = this._submitNewsletterSignup.bind(this);

    this.state = {
      error: '',
      success: '',
      inProgress: false,
      email: '',
    }
  }

  render() {
    const { error, success, inProgress, email } = this.state;

    return <>
      <Backdrop open={inProgress} style={{ zIndex:2000 }}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={error.length > 0 || success.length > 0}
        autoHideDuration={6000}
        onClose={() => this.setState({error: '', success: ''})}
      >
        <Alert severity={error ? 'error' : 'success'}>{error || success}</Alert>
      </Snackbar>

      <ValidatorForm
        ref="form"
        className="-newsletter-signup-form"
        onSubmit={this._submitNewsletterSignup}
      >
        <CssTextField
          name="email"
          label="Email Address"
          variant="outlined"
          id="email-address"
          value={email}
          validators={['required', 'isEmail']}
          errorMessages={['Please enter a valid email.']}
          onChange={(event) => {
            const email = event.target.value;
            this.setState({ email: email })
          }}
        />
        <Button variant="contained" color="secondary" type="submit" className="footer -subscribe-button" aria-label="Subscribe to Receive Position Announcements">Subscribe</Button>
      </ValidatorForm>
    </>;
  }

  async _submitNewsletterSignup(event) {
    const form = event.target;

    this.setState({ inProgress: true });

    fetch("/api/email/signup", {
      method : "POST",
      body: JSON.stringify({
        _method: 'post',
        email: form.email.value,
      })
    })
    .then( response => response.json() )
    .then( ({ success, error }) => {
      if (success) {
        // show success message
        this.setState({ inProgress: false, error: '', success: 'Your request as been sent.' });
        return;
      }

      // show failed message failed
      this.setState({ inProgress: false, error: error, success: '' });
    })
    .catch( () => {
      this.setState({ inProgress: false, error: 'An error has occurred with this request.  Please try again.', success: '' });
    });
  }
}

export default NewsletterSignup

