import React, { Component } from 'react';
import { Link } from 'gatsby';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import MenuIcon from '@material-ui/icons/Menu';
import Logo from '../assets/Pauly-Group-Logo.svg';
import PastClientColleges from '../assets/Past_Client_Colleges_05-2020.pdf';
import DesktopBackground from './DesktopBackground';
import MobileBackground from './MobileBackground';
import LoginAction from './loginaction';
import './css/header.css';

class DesktopHomepageHeader extends Component {
  render() {
    return (
      <div className="homepage -header">
        <DesktopBackground loginAction={<LoginAction clientEmail={this.props.clientEmail} schoolList={this.props.schoolList} setLoginDetails={this.props.setLoginDetails} />} />
      </div>
    );
  }
}

class MobileHomepageHeader extends Component {
  render() {
    return (
      <div className="homepage -mobile-header">
        <ExpansionPanel className="mobile -menu-container">
          <ExpansionPanelSummary expandIcon={<MenuIcon className="mobile-header -menu-icon" aria-label="Menu" />} aria-controls="panel2a-content" id="panel2a-header">
            <div className="mobile -site-logo">
              <Link to="/" aria-label="Go to homepage"><Logo className="mobile -logo-image" /></Link>
            </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div className="mobile-navigation -container">
              <ul className = "mobile-navigation">
                <li><Link to="/what-we-do">WHAT WE DO</Link></li>
                <li><Link to="/success-stories">SUCCESS STORIES</Link></li>
                <li><Link to="/who-we-are">WHO WE ARE</Link></li>
                <li><Link to="/positions">POSITIONS</Link></li>
                <li><Link to="/candidates">CANDIDATE RESOURCES</Link></li>
                <li><a href={PastClientColleges} target="_blank" rel="noopener noreferrer">PAST CLIENT COLLEGES</a></li>
                <LoginAction mobile clientEmail={this.props.clientEmail} schoolList={this.props.schoolList} setLoginDetails={this.props.setLoginDetails} />
              </ul>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <MobileBackground />
      </div>
    );
  }
}


class DesktopHeader extends Component {
  render() {
    return (
      <div className="desktop -header">
        <div className="header -container">
          <Grid>
            <div className="header-upper">
              <div className="site-logo">
                <Link to="/" aria-label="Go to homepage"><Logo className="logo-image" /></Link>
              </div>
              <div className="site-buttons">
                <a href={PastClientColleges} target="_blank" rel="noopener noreferrer">
                  <Button className="header -client-button" aria-label="Past Client Colleges List">Past Client Colleges</Button>
                </a>
                <LoginAction clientEmail={this.props.clientEmail} schoolList={this.props.schoolList} setLoginDetails={this.props.setLoginDetails} />
              </div>
            </div>
          </Grid>
          <div className="header-lower">
            <ul className = "navigation">
              <li><Link to="/what-we-do">WHAT WE DO</Link></li>
              <li><Link to="/success-stories">SUCCESS STORIES</Link></li>
              <li><Link to="/who-we-are">WHO WE ARE</Link></li>
              <li><Link to="/positions">POSITIONS</Link></li>
              <li><Link to="/candidates">CANDIDATE RESOURCES</Link></li>
            </ul>
          </div>
        </div>
      </div>
    )
  }
}

class MobileHeader extends Component {
  render() {
    return (
      <div className="site-header -mobile">
        <div className="mobile-header -container">
        <ExpansionPanel className="mobile -menu-container">
          <ExpansionPanelSummary expandIcon={<MenuIcon className="mobile-header -menu-icon" aria-label="Site Navigation Menu" />} aria-controls="panel2a-content" id="panel2a-header">
            <div className="mobile -site-logo">
              <Link to="/" aria-label="Go to homepage"><Logo className="mobile -logo-image" /></Link>
            </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div className="mobile-navigation -container">
              <ul className = "mobile-navigation">
                <li><Link to="/what-we-do">WHAT WE DO</Link></li>
                <li><Link to="/success-stories">SUCCESS STORIES</Link></li>
                <li><Link to="/who-we-are">WHO WE ARE</Link></li>
                <li><Link to="/positions">POSITIONS</Link></li>
                <li><Link to="/candidates">CANDIDATE RESOURCES</Link></li>
                <li><a href={PastClientColleges} target="_blank" rel="noopener noreferrer">PAST CLIENT COLLEGES</a></li>
                <LoginAction mobile clientEmail={this.props.clientEmail} schoolList={this.props.schoolList} setLoginDetails={this.props.setLoginDetails} />
              </ul>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        </div>
      </div>
    );
  }
}


class DefaultHomepageHeader extends Component {
  render() {
    return (
      <>
        <div className='-desktop'><DesktopHomepageHeader clientEmail={this.props.clientEmail} schoolList={this.props.schoolList} setLoginDetails={this.props.setLoginDetails} /></div>
        <div className='-mobile'><MobileHomepageHeader clientEmail={this.props.clientEmail} schoolList={this.props.schoolList} setLoginDetails={this.props.setLoginDetails} /></div>
      </>
    )
  }
}

class DefaultHeader extends Component {
  render() {
    return (
      <>
        <div className='-desktop'><DesktopHeader clientEmail={this.props.clientEmail} schoolList={this.props.schoolList} setLoginDetails={this.props.setLoginDetails} /></div>
        <div className='-mobile'><MobileHeader clientEmail={this.props.clientEmail} schoolList={this.props.schoolList} setLoginDetails={this.props.setLoginDetails} /></div>
      </>
    );
  }
}

export default class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      schoolList: [],
      clientEmail: '',
    };

    this.setLoginDetails = this.setLoginDetails.bind(this);
  }

  componentDidMount() {
    this._checkLoggedIn();
  }

  _checkLoggedIn() {
    const options = {
      method: 'POST',
      mode: 'same-origin',
      credentials: 'same-origin',
      headers: {
        'Accept': 'application/json',
      },
    };

    fetch(new Request('/api/authenticate'), options)
      .then((response) => {
         const contentType = response.headers.get('content-type');
         if (!contentType || !contentType.includes('application/json')) {
           console.log("Unable to authenticaate!");
         }
         return response.json();
      })
      .then( ({ username, schools }) => {
        this.setState({
          schoolList: schools || [],
          clientEmail: username || ''
        });
      });
  }

  setLoginDetails(username, schoolList) {
    this.setState({ clientEmail: username, schoolList: schoolList });
  }

  render() {
    const { isHomePage } = this.props;
    const { clientEmail, schoolList } = this.state;

    // Display header based of if its the homepage or not
    if (isHomePage) {
      return (
        <React.Fragment>
          <DefaultHomepageHeader clientEmail={clientEmail} schoolList={schoolList} setLoginDetails={this.setLoginDetails} />
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <DefaultHeader clientEmail={clientEmail} schoolList={schoolList} setLoginDetails={this.setLoginDetails} />
      </React.Fragment>
    );
  }
}
