import React from 'react'
import { Link, graphql, StaticQuery } from 'gatsby'
import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Logo from '../assets/Pauly-Group-Logo.svg';
import PastClientColleges from '../assets/Past_Client_Colleges_05-2020.pdf';

const DesktopBackground = ({ className, loginAction }) => (
  <StaticQuery
    query={ graphql`
      query {
        headerImg: file(relativePath: { eq: "Homepage-Banner.jpg" }) {
          childImageSharp {
            fluid(quality: 60, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    `}
    render={ data => {
      const imageData = data.headerImg.childImageSharp.fluid
      return (
        <BackgroundImage
          Tag="section"
          className={className}
          fluid={imageData}
          backgroundColor="#00324A"
        >
          <div className="header -container">
            <Grid>
              <div className="header-upper">
                <div className="site-logo">
                  <Link to="/" aria-label="Go to homepage"><Logo className="logo-image" /></Link>
                </div>
                <div className="site-buttons">
                  <a href={PastClientColleges} target="_blank" rel="noopener noreferrer">
                    <Button className="header -client-button" aria-label="Link to Portfolio of Past Client Colleges">Past Client Colleges</Button>
                  </a>
                  {loginAction}
                </div>
              </div>
            </Grid>
            <div className="header-lower">
              <ul className = "navigation">
                <li><Link to="/what-we-do">WHAT WE DO</Link></li>
                <li><Link to="/success-stories">SUCCESS STORIES</Link></li>
                <li><Link to="/who-we-are">WHO WE ARE</Link></li>
                <li><Link to="/positions">POSITIONS</Link></li>
                <li><Link to="/candidates">CANDIDATE RESOURCES</Link></li>
              </ul>
            </div>
            <div className="homepage -header-subheading">
              <h4 className="homepage -header-learnmore">WE MAKE A DIFFERENCE</h4>
              <h2>
                <span style={{whiteSpace:'nowrap'}}>Setting the Standard</span> <span style={{whiteSpace:'nowrap'}}>for Search Excellence</span> <span style={{whiteSpace:'nowrap'}}>Since 1990</span>
              </h2>
              <Link to="/what-we-do">
                <Button className="header -learn-button" aria-label="Learn More About Pauly Group">LEARN MORE</Button>
              </Link>
            </div>
          </div>
        </BackgroundImage>
      )
    }}
  />
)

const StyledDesktopBackground = styled(DesktopBackground)`
  width: 100%;
  opacity: 1 !important;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
`

export default StyledDesktopBackground
