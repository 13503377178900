import React, { Component } from 'react';
import { Link } from 'gatsby';
import Grid from '@material-ui/core/Grid';
import Logo from '../assets/Pauly-Group-Logo.svg';
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import NewsletterSignup from './NewsletterSignup';
import './css/footer.css';

class DesktopFooter extends Component {
  render() {
    return (
      <div className="footer">
        <div className="footer -container">
          <Grid container spacing={3} alignItems="center">
            <Grid item sm={9} md={4}>
              <Link to="/" aria-label="Go to homepage"><Logo className = "footer -paulygroup-logo" /></Link>
              <p className="footer -newsletter-info">At Pauly Group, we're proud
              to be one of the leading academic search consulting firms in the nation.
              Since our founding, we have focused solely on senior administrative searches
              for community and technical colleges. This singular focus has allowed us to
              consistently deliver success to our clients.</p>
              <h4 className="footer -newsletter-signup">Subscribe to Receive Position Announcements <EmailOutlinedIcon className="footer -subscribe-icon" /></h4>
              <NewsletterSignup />
	      <h4>To sign up for text alerts, text the keyword <span style={{color:'#cd9953'}}>position</span> to <span style={{color:'#cd9953'}}>833-585-1646</span>.</h4>
            </Grid>
            <Grid item sm={9} md={4}>
              <div className="footer -explore">
                <h4 className="footer -explore-header">Explore Pauly Group</h4>
                <ul className="footer -explore-list">
                  <li><Link to="/what-we-do/">What We Do</Link></li>
                  <li><Link to="/success-stories/">Success Stories</Link></li>
                  <li><Link to="/who-we-are/">Who We Are</Link></li>
                  <li><Link to="/positions/">Positions</Link></li>
                  <li><Link to="/candidates/">Candidate Resources</Link></li>
                </ul>
              </div>
            </Grid>
            <Grid item sm={9} md={4}>
              <div className="footer -contact">
                <h4 className="footer -contact-header">Contact Us</h4>
                <h4 className="footer -phone-header"><PhoneOutlinedIcon className="footer -phone-icon" /> Phone</h4>
                <p className="footer -phone">O: 217.241.5400</p>
                <p className="footer -fax">F: 217.241.5401</p>
                <h4 className="footer -location-header"><LocationOnOutlinedIcon className="footer -location-icon" /> Address</h4>
                <p className="footer -address">3901 Wood Duck Drive, Ste. E</p>
                <p className="footer -address2">Springfield, IL 62711</p>
                <h4 className="footer -email-header"><EmailOutlinedIcon className="footer -email-icon" /> Email</h4>
                <p className="footer -email"><a href="mailto:aprovart@paulygroup.com">aprovart@paulygroup.com</a></p>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className="footer -misc-info">
          <Divider />
          <Grid container direction="row" alignItems="center">
            <Grid item sm>
              <h4>Copyright &copy; 2020 Pauly Group, Inc</h4>
            </Grid>
            <Grid item sm>
              <div className="footer -icon-container">
                <a href="https://twitter.com/PaulyGroupInc">
                  <IconButton className="footer -twitter" aria-label="Pauly Group Twitter Profile">
                    <TwitterIcon />
                  </IconButton>
                </a>
                <a href="http://www.linkedin.com/pub/pauly-group-inc/66/229/a73/">
                  <IconButton className="footer -linkedin" aria-label="Pauly Group LinkedIn Profile">
                    <LinkedInIcon />
                  </IconButton>
                </a>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

class MobileFooter extends Component {
  render() {
    return (
      <div className="mobile-footer">
        <div className="mobile-footer -container">
          <div className="mobile-footer -info">
            <Link to="/" aria-label="Go to homepage"><Logo className="footer -paulygroup-logo" alt="Pauly Group Logo"/></Link>
            <p>At Pauly Group, we're proud to be one of the leading academic search
            consulting firms in the nation. Since our founding, we have focused
            solely on senior administrative searches for community and technical
            colleges. This singular focus has allowed us to consistently deliver
            success to our clients.</p>
          </div>
          <div className="mobile-footer -newsletter">
            <h3>
              Subscribe to Receive Position Announcements
              <EmailOutlinedIcon className="mobile-footer -newsletter-icon" />
            </h3>
            <NewsletterSignup />
            <h4>To sign up for text alerts, text the keyword <span style={{color:'#cd9953'}}>position</span> to <span style={{color:'#cd9953'}}>833-585-1646</span>.</h4>
          </div>
          <Divider />
          <div className="mobile-footer -explore">
            <h3 className="mobile-footer -explore-header">Explore Pauly Group</h3>
            <ul className="mobile-footer -explore-nav">
              <li><Link to="/what-we-do">What We Do</Link></li>
              <li><Link to="/success-stories">Success Stories</Link></li>
              <li><Link to="/who-we-are">Who We Are</Link></li>
              <li><Link to="/positions">Positions</Link></li>
              <li><Link to="/candidates">Candidate Resources</Link></li>
              <li><a href="/contact">Contact Us</a></li>
            </ul>
          </div>
          <Divider />
          <div className="mobile-footer -contact">
            <h3 className="mobile-footer -contact-header">Contact Us</h3>
            <h4><PhoneOutlinedIcon className="mobile-footer -phone-icon" /> Phone</h4>
            <p>O: 217.241.5400<br />F: 217.241.5401</p>
            <h4><LocationOnOutlinedIcon className="mobile-footer -location-icon" /> Address</h4>
            <p>3901 Wood Duck Drive, Ste. E<br />Springfield, IL 62711</p>
            <h4><EmailOutlinedIcon className="mobile-footer -email-icon" /> Email</h4>
            <p className="mobile-footer -contact-email"><a href="mailto:aprovart@paulygroup.com">aprovart@paulygroup.com</a></p>
          </div>
          <div className="mobile-footer -misc">
            <Divider />
            <Grid container direction="row" alignItems="center">
              <Grid item sm>
                <h4>Copyright &copy; 2020 Pauly Group, Inc</h4>
              </Grid>
              <Grid item sm>
                <div className="footer -icon-container">
                  <a href="https://twitter.com/PaulyGroupInc">
                    <IconButton className="footer -twitter" aria-label="Pauly Group Twitter Profile">
                      <TwitterIcon aria-label="Twitter"/>
                    </IconButton>
                  </a>
                  <a href="http://www.linkedin.com/pub/pauly-group-inc/66/229/a73/">
                    <IconButton className="footer -linkedin" aria-label="Pauly Group LinkedIn Profile">
                      <LinkedInIcon aria-label="LinkedIn" />
                    </IconButton>
                  </a>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    );
  }
}

export default class Footer extends Component {
  render() {
    return (
      <div id="footer">
        <div className='-desktop'><DesktopFooter/></div>
        <div className='-mobile'><MobileFooter/></div>
      </div>
    )
  }
}
