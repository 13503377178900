import React from 'react';
import Button from '@material-ui/core/Button';
import LoginDialog from './logindialog';
import ViewApplicantsDialog from './viewapplicantsdialog';

class LoginAction extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      openViewApplicants: false,
      openLoginDialog: false,
    };
  }

  render() {
    const { schoolList, clientEmail, setLoginDetails, mobile } = this.props;
    const { openViewApplicants, openLoginDialog } = this.state;

    return (
        clientEmail && clientEmail !== '' ? (
          <>
            <Button
              component={mobile ? "a" : "button"}
              className={mobile ? "" : "header -login-button"}
              onClick={() => this.setState({ openViewApplicants: true})} style={{marginRight:'1rem'}}
            >
              View Applicants
            </Button>

            <Button
              component={mobile ? "a" : "button"}
              className={mobile ? "" : "header -login-button"}
              onClick={() => {
                // logout
                fetch(new Request('/api/logout'), { redirect:'manual' });
                setLoginDetails('', []);
              }}
            >
              Logout
            </Button>

            <ViewApplicantsDialog
              openViewApplicants={openViewApplicants}
              schoolList={schoolList}
              onClose={() => this.setState({ openViewApplicants: false })}
            />
          </>
        ) : (
          <>
            <Button
              component={mobile ? "a" : "button"}
              className={mobile ? "" : "header -login-button"}
              aria-label="Client Login"
              onClick={() => this.setState({ openLoginDialog: true })}
            >
              Client Login
            </Button>

            <LoginDialog
              open={openLoginDialog}
              handleClose={() => this.setState({ openLoginDialog: false })}
              setLoginDetails={setLoginDetails}
            />
          </>
      )
    )
  }
}

export default LoginAction;
