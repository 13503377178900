import React, { useState } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import NativeSelect from '@material-ui/core/NativeSelect';
import Typography from '@material-ui/core/Typography';
import ApplicantsTable from "./applicantstable";
import DownloadButton from "./downloadbutton";

const ViewApplicantsDialog = ({ openViewApplicants, onClose, schoolList }) => {
  const defaultSchool = schoolList.length === 1 ? schoolList[0].id.toString() : '';
  const [selectedSchool, setSelectedSchool] = useState(defaultSchool);
  const [selectedPosition, setSelectedPosition] = useState('');
  const [error, setError] = useState('');
  const [filter, setFilter] = useState('');
  const [inProgress, setInProgress] = useState(false);
  const schoolItem = schoolList.find(s => s.id === parseInt(selectedSchool));

  const positionItem = schoolItem
     ? schoolItem.positions.find(p => p.id === parseInt(selectedPosition))
     : null;

  return (
    <div>
      <Backdrop open={inProgress} style={{ zIndex:2000 }}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={openViewApplicants && error !== ''}
        autoHideDuration={6000}
        onClose={() => setError('')}
      >
        <Alert severity="error">{error}</Alert>
      </Snackbar>

      <Dialog open={openViewApplicants} onClose={onClose} aria-labelledby="form-dialog-title" fullScreen>
        <DialogTitle id="form-dialog-title">
          <Grid container justify="space-between">
            <Grid item>Applicants</Grid>
            <Grid item><IconButton onClick={onClose}><CloseIcon /></IconButton></Grid>
          </Grid>
        </DialogTitle>

        <DialogContent>
          <Grid container justify="space-between" style={{ paddingBottom:'0.5rem', alignItems:'center' }}>
            <Grid item xs={3}>
              <DrawSchool/>
            </Grid>

            <Grid item xs={3}>
              <DrawPosition/>
            </Grid>

            <Grid item xs={3}>
              <TextField
                label="Search"
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
                fullWidth
              />
            </Grid>
          </Grid>

          { !schoolItem ? null : (
            <Grid container justify="space-between" style={{ paddingBottom:'0.5rem' }}>
              {schoolItem['private_files'].map( f =>
                <Grid item xs={3} key={"schoolItem_private_files:"+f.id}>
                  <DownloadButton href={"/api/school/private/files/"+f.id}>{f.desc}</DownloadButton>
                </Grid>
              )}

              { !positionItem ? null : (
                positionItem['private_files'].map( f =>
                  <Grid item xs={3} key={"positionItem_private_files:"+f.id}>
                    <DownloadButton href={"/api/position/private/download/"+f.filename}>{f.desc}</DownloadButton>
                  </Grid>
                )
              )}
            </Grid>
          )}

          <ApplicantsTable schoolId={selectedSchool} positionId={selectedPosition} setInProgress={setInProgress} setError={setError} />
        </DialogContent>

        <DialogActions>
          <Button
            onClick={onClose}
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );

  function DrawSchool() {
    if (schoolList.length === 1) {
        setSelectedSchool(schoolList[0].id);

          return (
              <Grid container spacing={2} alignItems="center">
                  <Grid item>
                      <div className="-school-logo">
                          <img
                              alt={schoolList[0].name}
                              src={"/api/school/" + schoolList[0].id + "/logo"}
                              title={schoolList[0].name}
                          />
                      </div>
                  </Grid>

                  <Grid item>
                      <Typography>{schoolList[0].name}</Typography>
                  </Grid>
              </Grid>
          );
      }

      return (
        <>
          <InputLabel htmlFor="school-select">School</InputLabel>
          <NativeSelect
              value={selectedSchool}
              fullWidth
              onChange={
                (e) => {
                  if (e.target.value === '') {
                    setSelectedSchool(0);
                    return;
                  }

                  setSelectedSchool(parseInt(e.target.value));
                  setSelectedPosition(0);
                }
              }
              inputProps={{id:'school-select'}}
          >
            <option value=""> </option>
            {schoolList.map( (s) => (<option key={"schoolList:"+s.id} value={s.id}>{s.name}</option>) )}
          </NativeSelect>
        </>
    )
  }

  function DrawPosition() {
      if (!selectedSchool) {
          return <></>;
      }

      const selectedSchoolObjects = schoolList.filter( (s) => s.id === selectedSchool );
      const selectedSchoolObject = selectedSchoolObjects.length === 1 ? selectedSchoolObjects[0] : null;

      if (selectedSchoolObject) {
          if (selectedSchoolObject.positions.length === 0) {
              setSelectedPosition(0);
              
              return (
                  <>
                      <InputLabel htmlFor="position-select">Position</InputLabel>
                      <Typography style={{paddingTop:'10px'}}>No Positions Available</Typography>
                  </>
              )
          }

          if (selectedSchoolObject.positions.length === 1) {
              setSelectedPosition(selectedSchoolObject.positions[0].id);

              return (
                  <>
                    <InputLabel htmlFor="position-select">Position</InputLabel>
                    <Typography style={{paddingTop:'10px'}}>{selectedSchoolObject.positions[0].Title}</Typography>
                  </>
              );
          }

          return (
              <>
                  <InputLabel htmlFor="position-select">Position</InputLabel>
                  <NativeSelect
                      value={selectedPosition}
                      fullWidth
                      onChange={
                          (e) => {
                              if (e.target.value === '') {
                                  setSelectedPosition(0);
                                  return;
                              }

                              setSelectedPosition(parseInt(e.target.value));
                          }
                      }
                      inputProps={{id:'position-select'}}
                  >
                      <option value=""> </option>
                      {selectedSchoolObject.positions.map( (p) => (<option key={"positions:"+p.id} value={p.id}>{p.Title}</option>) )}
                  </NativeSelect>
              </>
          )
      }

      return ( <></> );
  }
};



export default ViewApplicantsDialog
