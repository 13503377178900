import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';

function login(username, password, success, failure) {
  const options = {
    method: 'POST',
    mode: 'same-origin',
    credentials: 'same-origin',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=utf-8'
    },
    body: JSON.stringify({ username:username, password:password }),
  };

  fetch(new Request('/api/login'), options)
    .then((response) => {
       const contentType = response.headers.get('content-type');
       if (!contentType || !contentType.includes('application/json')) {
         throw new TypeError("Received content type is not of type json.");
       }

       if (!response.ok && response.status !== 401) {
         throw new Error("Invalid http response code received.");
       }

       return response.json();
    })
    .then( (data) => {
      if (data.error) {
        failure('Incorrect username or password.')
        return;
      }

      success(username, data.schools);
    })
    .catch((error) => {
      console.error('Login: ' + error.message);

      // failed...
      failure('A service or network error has occurred.  Please try again.');
    });
}

const LoginDialog = ({ open, handleClose, setLoginDetails }) => {
  const [emailAddress, setEmailAddress] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [inProgress, setInProgress] = useState(false);

  return (
    <div>
      <Backdrop open={inProgress} style={{ zIndex:2000 }}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <form id="login" onSubmit={(e) => {
          e.preventDefault();
          setInProgress(true);
          login(
            emailAddress,
            password,
            (username, schoolList) => {
              setLoginDetails(emailAddress || '', schoolList || []);
              handleClose();
            },
            (errorMessage) => {
              setError(errorMessage);
              setLoginDetails('', []);
              setInProgress(false);
            },
          );
        }}>
          <DialogTitle id="form-dialog-title">Client Login</DialogTitle>
          <DialogContent>
            {error ? (<Alert severity="error">{error}</Alert>) : null}

            <TextField
              margin="dense"
              id="name"
              label="Email Address"
              type="email"
              fullWidth
              value={emailAddress}
              onChange={(e) => setEmailAddress(e.target.value)}
            />

            <TextField
              margin="dense"
              id="name"
              label="Password"
              type="password"
              fullWidth
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />

            <DialogContentText variant="caption" style={{paddingTop:'2rem'}}>
              This system is for the use of authorized users only. Individuals using this computer system without authority, or in excess of their authority, are subject to having all of their activities on this system monitored and recorded by system personnel. In the course of monitoring individuals improperly using this system, or in the course of system maintenance, the activities of authorized users may also be monitored. Anyone using this system expressly consents to such monitoring and is advised that if such monitoring reveals possible evidence of criminal activity, system personnel may provide the evidence of such monitoring to law enforcement officials.
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button
              onClick={
                () => {
                  setEmailAddress('');
                  setPassword('');
                  handleClose();
                }
              }
              color="secondary"
              variant="contained"
            >
              Cancel
            </Button>

            <Button type="submit" color="primary" variant="contained">
              Login
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}


export default LoginDialog;
