import React from 'react'
import { Link, graphql, StaticQuery } from 'gatsby'
import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'
import Button from '@material-ui/core/Button';

const MobileBackground = ({ className }) => (
  <StaticQuery
    query={ graphql`
      query {
        headerImg: file(relativePath: { eq: "Homepage-Banner.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    `}
    render={data => {
      const imageData = data.headerImg.childImageSharp.fluid
      return (
        <BackgroundImage
          Tag="section"
          className={className}
          fluid={imageData}
          backgroundColor="#00324A"
        >
          <div className="homepage -mobile-subheader-container">
            <h4>WE MAKE A DIFFERENCE</h4>
            <h2>
              <span style={{whiteSpace:'nowrap'}}>Setting the Standard</span> <span style={{whiteSpace:'nowrap'}}>for Search Excellence</span> <span style={{whiteSpace:'nowrap'}}>Since 1990</span>
            </h2>
            <Link to="/what-we-do"><Button className="header -learn-button" arai-label="Learn More About Pauly Group">LEARN MORE</Button></Link>
          </div>
        </BackgroundImage>
      )
    }}
  />
)

const StyledMobileBackground = styled(MobileBackground)`
  width: 100%;
  opacity: 1 !important;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`

export default StyledMobileBackground
