import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import React, {useEffect, useState} from "react";
import DownloadButton from "./downloadbutton";

const _formatDateTime = (dt) => {
    if (dt == null) return "";

    // convert to iso format
    if (dt.date.charAt(10) === ' ') {
        dt.date = dt.date.substr(0, 10) + 'T' + dt.date.substr(11);
    }

    const newDate = new Date(dt.date + "Z");

    return newDate.toLocaleString();
}

const getApplicants = (schoolId, positionId, success, failure) => {
    const options = {
        method: 'POST',
        mode: 'same-origin',
        credentials: 'same-origin',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=utf-8'
        },
    };

    fetch(new Request('/api/applications/'+schoolId+'/'+positionId), options)
        .then((response) => {
            const contentType = response.headers.get('content-type');
            if (!contentType || !contentType.includes('application/json')) {
                throw new TypeError("Oops, we haven't got JSON!");
            }
            return response.json();
        })
        .then( (applications) => {
            if (applications.length === 0) {
                failure('No applications found');
                return;
            }

            success(applications);
        })
        .catch(() => {
            // failed...
            failure('A service or network error has occurred.  Please try again.');
        });
};

const _buildName = ({ LastName, FirstName, MiddleName, Suffix }) => {
    return (
        ( (LastName || '') + ' ' + (Suffix || '') ).trim() +
        ', ' +
        ( (FirstName || '') + ' ' + (MiddleName || '') ).trim()
    );
}

const _buildSearch = (name, { LastName, FirstName, MiddleName, Suffix, Phone, Email, JobPostingTitle }) => {
    return [name, FirstName, MiddleName, LastName, Suffix, Phone, Email, JobPostingTitle].filter( (r) => r ).map( (r) => r.trim() ).join(' ').toLowerCase();
}

const _positionSort = (a,b) => {
    const rankCode = {
        "Not Qualified": 100,
        "Qualified": 200,
        "Preferred": 300,
    };

    if (a.applicationStatus === b.applicationStatus) {
        const aTime = a.applyDateTime || {date:""};
        const bTime = b.applyDateTime || {date:""};

        // sort by date (DESC)
        return aTime.date.localeCompare(bTime.date) * -1;
    } else if (rankCode[a.applicationStatus] > rankCode[b.applicationStatus]) {
        return -1;
    } else {
        return 1;
    }
}

const ApplicantsTable = ({schoolId, positionId, setInProgress, setError}) => {
    const [applicants, setApplicants] = useState([]);
    const [filter] = useState('');

    useEffect( () => {
        if (schoolId && positionId) {
            setInProgress(true);

            getApplicants(
                schoolId,
                positionId,
                (applicants) => {
                    setInProgress(false);

                    setApplicants(
                        applicants
                            .sort(_positionSort)
                            .map(
                                (r) => (
                                    {
                                        ...r,
                                        name:  _buildName(r),
                                        searchKey:  _buildSearch(_buildName(r), r),
                                    }
                                )
                            )
                    )
                }, // success
                (error) => {   // faliure
                    setInProgress(false);
                    setApplicants([]);
                    setError(error);
                }
            );
        } else {
            setApplicants([]);
        }
    }, [schoolId, positionId, setInProgress, setError]);

    if (applicants.length === 0) {
        return ( <></> );
    }

    const rows = filter === '' ? applicants : applicants.filter( (r) => r.searchKey.indexOf(filter.toLowerCase()) !== -1 );

    return (
      <TableContainer component={Paper}>
          <Table size="small" aria-label="a dense table">
              <TableHead>
                  <TableRow>
                      <TableCell>Application Ranking</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Position</TableCell>
                      <TableCell>Apply Date/Time</TableCell>
                      <TableCell>Downloads</TableCell>
                  </TableRow>
              </TableHead>
              <TableBody>
                  {(rows || []).map((r) => (
                      <TableRow key={"applicants:"+r.ApplicationId}>
                          <TableCell>
                              {r.applicationStatus}
                              {r.applicationNotes === "" ? null :
                                  <span dangerouslySetInnerHTML={{__html:r.applicationNotes}} />
                              }
                          </TableCell>
                          <TableCell>{r.name}</TableCell>
                          <TableCell>{r.JobPostingTitle}</TableCell>
                          <TableCell>{_formatDateTime(r.applyDateTime)}</TableCell>
                          <TableCell>
                              {r.coverLetterFileName ? <DownloadButton href={"/api/document/"+r.ApplicationId+"/"+r.coverLetterFileName}>Cover Letter</DownloadButton> : null}
                              {r.resumeFileName ? <DownloadButton href={"/api/document/"+r.ApplicationId+"/"+r.resumeFileName}>Resume/CV</DownloadButton> : null}
                              {r.referencesFileName ? <DownloadButton href={"/api/document/"+r.ApplicationId+"/"+r.referencesFileName}>References</DownloadButton> : null}
                              {r.applicationMaterialsFileName ? <DownloadButton href={"/api/document/"+r.ApplicationId+"/"+r.applicationMaterialsFileName}>Application Materials</DownloadButton> : null}
                              {r.applicationFiles ? r.applicationFiles.map((file) => (
                                  <DownloadButton key={"applicant:"+r.ApplicationId+"_file:"+file[0]} href = {"/api/document/"+r.ApplicationId+"/"+file[0]}>
                                      {file[1]}
                                  </DownloadButton>
                              )) : null }
                          </TableCell>
                      </TableRow>
                  ))}
              </TableBody>
          </Table>
      </TableContainer>
    );
};

export default ApplicantsTable;
