/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, {useContext} from "react";
import PropTypes from "prop-types"
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { useStaticQuery, graphql } from "gatsby"

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';

import Header from './header';
import Footer from './footer';
import './layout.css';
import {GlobalContext} from "../context/GlobalContext";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#00324a',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#CD9953',
      contrastText: '#ffffff',
    },
  },
  typography: {
    fontFamily: 'Raleway, Spectral',
  },
});

export const Progress = <>
  <div className="progress-wrapper">
    <CircularProgress color="secondary" />
  </div>
</>;

export const FullProgress = ({open}) => <>
  <Backdrop open={open} style={{ zIndex:2000 }}>
    <CircularProgress color="secondary" />
  </Backdrop>
</>;

const Layout = ({ children, isHomePage }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const { messageDialog, setMessageDialog, fullInProgress } = useContext(GlobalContext);

  return (
    <ThemeProvider theme={theme}>
      <Header
        siteTitle={data.site.siteMetadata.title}
        isHomePage={isHomePage}
      />
      <main>{children}</main>
      <Footer />

      <FullProgress open={fullInProgress} />

      {messageDialog ?
        <Dialog open={true}>
          <DialogContent>
            <Alert severity={messageDialog.severity}>{messageDialog.message}</Alert>
          </DialogContent>

          <DialogActions style={{justifyContent:'center'}}>
            <Button variant='contained' color='primary' onClick={() => setMessageDialog(null)}>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      : null}


    </ThemeProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
